<template>
  <div class="relative px-4 sm:px-6 md:px-20 py-6 sm:py-8 md:py-16">
    <!-- Background Image with Parallax -->
    <div class="absolute inset-0 overflow-hidden">
      <div class="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1519681393784-d120267933ba')] bg-cover bg-center bg-fixed transform scale-105"></div>
      <!-- Gradient Overlay -->
      <div class="absolute inset-0 bg-gradient-to-br from-white/80 via-white/75 to-white/70"></div>
    </div>

    <!-- Main Content -->
    <div class="relative bg-white/70 backdrop-blur-sm rounded-xl sm:rounded-2xl md:rounded-3xl p-4 sm:p-6 md:p-16 shadow-xl transform hover:scale-[1.01] transition-all duration-300 border border-white/20">
      <!-- Decorative Elements -->
      <div class="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#2B8B6F]/10 to-transparent rounded-full blur-3xl"></div>
      <div class="absolute bottom-0 left-0 w-40 h-40 bg-gradient-to-tr from-[#2B8B6F]/10 to-transparent rounded-full blur-3xl"></div>
      
      <div class="text-center mb-8 sm:mb-12 md:mb-16 relative">
        <h2 class="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-[#2B8B6F] opacity-0 animate-slide-up animation-delay-200">
          {{ t('home.howItWorks.title') }}
        </h2>
        <p class="text-gray-800 text-sm sm:text-base max-w-2xl mx-auto opacity-0 animate-slide-up animation-delay-400 leading-relaxed">
          {{ t('home.howItWorks.description') }}
        </p>
        
        <!-- Decorative Line -->
        <div class="w-20 h-1 bg-gradient-to-r from-[#2B8B6F]/50 via-[#2B8B6F] to-[#2B8B6F]/50 mx-auto mt-6 rounded-full"></div>
      </div>
      
      <div class="flex flex-col lg:flex-row gap-8 sm:gap-12 md:gap-20">
        <!-- Careseeker Section -->
        <div class="lg:w-1/2 relative group p-2">
          <div class="absolute inset-0 bg-white/60 backdrop-blur-[2px] rounded-3xl transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
          
          <h3 class="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 md:mb-12 opacity-0 animate-slide-up animation-delay-600 text-[#2B8B6F] relative">
            {{ t('home.howItWorks.careseeker.title') }}
          </h3>
          
          <div class="space-y-4 sm:space-y-6 md:space-y-8 relative">
            <div v-for="(step, index) in careseekerSteps" :key="index"
                 class="flex gap-3 sm:gap-4 items-start p-3 sm:p-4 rounded-lg sm:rounded-xl bg-white/80 hover:bg-white transition-all duration-300 opacity-0 animate-slide-up group/step relative shadow-sm hover:shadow-md"
                 :style="{ animationDelay: `${(index + 4) * 200}ms` }">
              <!-- Step Number -->
              <div class="absolute -left-3 -top-3 w-6 h-6 rounded-full bg-[#2B8B6F] text-white flex items-center justify-center text-xs font-semibold opacity-0 group-hover/step:opacity-100 transform -translate-y-1 group-hover/step:translate-y-0 transition-all duration-300">
                {{ index + 1 }}
              </div>
              
              <div class="w-10 h-10 sm:w-12 sm:h-12 bg-[#E8F5F1] group-hover/step:bg-[#2B8B6F] rounded-lg sm:rounded-xl flex items-center justify-center flex-shrink-0 transform transition-all duration-500 hover:rotate-12 group-hover/step:scale-110 relative overflow-hidden">
                <div class="absolute inset-0 bg-gradient-to-br from-transparent via-white/10 to-transparent opacity-0 group-hover/step:opacity-100 transition-all duration-300"></div>
                <component :is="stepIcons[index]" class="w-5 h-5 sm:w-6 sm:h-6 text-[#2B8B6F] group-hover/step:text-white transition-all duration-300" />
              </div>
              
              <div class="flex-1">
                <h4 class="text-lg sm:text-xl font-semibold mb-1 sm:mb-2 text-gray-800 group-hover/step:text-[#2B8B6F] transition-colors duration-300">
                  {{ step.title }}
                </h4>
                <p class="text-sm sm:text-base text-gray-700 group-hover/step:text-gray-800 transition-colors duration-300 leading-relaxed">
                  {{ step.description }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Carer Section -->
        <div class="lg:w-1/2 relative group p-2">
          <div class="absolute inset-0 bg-white/60 backdrop-blur-[2px] rounded-3xl transition-opacity duration-300 opacity-0 group-hover:opacity-100"></div>
          
          <h3 class="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 md:mb-12 opacity-0 animate-slide-up animation-delay-600 text-[#2B8B6F] relative">
            {{ t('home.howItWorks.carer.title') }}
          </h3>
          
          <div class="space-y-4 sm:space-y-6 md:space-y-8 relative">
            <div v-for="(step, index) in carerSteps" :key="index"
                 class="flex gap-3 sm:gap-4 items-start p-3 sm:p-4 rounded-lg sm:rounded-xl bg-white/80 hover:bg-white transition-all duration-300 opacity-0 animate-slide-up group/step relative shadow-sm hover:shadow-md"
                 :style="{ animationDelay: `${(index + 4) * 200}ms` }">
              <!-- Step Number -->
              <div class="absolute -left-3 -top-3 w-6 h-6 rounded-full bg-[#2B8B6F] text-white flex items-center justify-center text-xs font-semibold opacity-0 group-hover/step:opacity-100 transform -translate-y-1 group-hover/step:translate-y-0 transition-all duration-300">
                {{ index + 1 }}
              </div>
              
              <div class="w-10 h-10 sm:w-12 sm:h-12 bg-[#E8F5F1] group-hover/step:bg-[#2B8B6F] rounded-lg sm:rounded-xl flex items-center justify-center flex-shrink-0 transform transition-all duration-500 hover:rotate-12 group-hover/step:scale-110 relative overflow-hidden">
                <div class="absolute inset-0 bg-gradient-to-br from-transparent via-white/10 to-transparent opacity-0 group-hover/step:opacity-100 transition-all duration-300"></div>
                <component :is="carerIcons[index]" class="w-5 h-5 sm:w-6 sm:h-6 text-[#2B8B6F] group-hover/step:text-white transition-all duration-300" />
              </div>
              
              <div class="flex-1">
                <h4 class="text-lg sm:text-xl font-semibold mb-1 sm:mb-2 text-gray-800 group-hover/step:text-[#2B8B6F] transition-colors duration-300">
                  {{ step.title }}
                </h4>
                <p class="text-sm sm:text-base text-gray-700 group-hover/step:text-gray-800 transition-colors duration-300 leading-relaxed">
                  {{ step.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CalendarCheck, MessageSquare, Plane, UserPlus, Calendar, CheckCircle, Star, ThumbsUp, Gift, Users } from 'lucide-vue-next'

interface Step {
  title: string
  description: string
}

const { t } = useI18n()

const stepIcons = [CalendarCheck, Users, MessageSquare, Plane, ThumbsUp]
const carerIcons = [UserPlus, Calendar, CheckCircle, Star, Gift]

const careseekerSteps = computed<Step[]>(() => {
  const steps = t('home.howItWorks.careseeker.steps') as unknown as Step[]
  return Array.isArray(steps) ? steps : [
    {
      title: t('home.howItWorks.careseeker.steps.0.title'),
      description: t('home.howItWorks.careseeker.steps.0.description')
    },
    {
      title: t('home.howItWorks.careseeker.steps.1.title'),
      description: t('home.howItWorks.careseeker.steps.1.description')
    },
    {
      title: t('home.howItWorks.careseeker.steps.2.title'),
      description: t('home.howItWorks.careseeker.steps.2.description')
    },
    {
      title: t('home.howItWorks.careseeker.steps.3.title'),
      description: t('home.howItWorks.careseeker.steps.3.description')
    },
    {
      title: t('home.howItWorks.careseeker.steps.4.title'),
      description: t('home.howItWorks.careseeker.steps.4.description')
    }
  ]
})

const carerSteps = computed<Step[]>(() => {
  const steps = t('home.howItWorks.carer.steps') as unknown as Step[]
  return Array.isArray(steps) ? steps : [
    {
      title: t('home.howItWorks.carer.steps.0.title'),
      description: t('home.howItWorks.carer.steps.0.description')
    },
    {
      title: t('home.howItWorks.carer.steps.1.title'),
      description: t('home.howItWorks.carer.steps.1.description')
    },
    {
      title: t('home.howItWorks.carer.steps.2.title'),
      description: t('home.howItWorks.carer.steps.2.description')
    },
    {
      title: t('home.howItWorks.carer.steps.3.title'),
      description: t('home.howItWorks.carer.steps.3.description')
    },
    {
      title: t('home.howItWorks.carer.steps.4.title'),
      description: t('home.howItWorks.carer.steps.4.description')
    }
  ]
})
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

.animate-slide-up {
  animation: slideUp 0.6s ease-out forwards;
}

.animation-delay-200 {
  animation-delay: 200ms;
}

.animation-delay-400 {
  animation-delay: 400ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

/* Add smooth transition for all interactive elements */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Mobile-specific optimizations */
@media (max-width: 640px) {
  .animate-slide-up {
    animation-duration: 0.4s;
  }

  /* Optimize touch interactions */
  .transition-all {
    transition-duration: 150ms;
  }

  /* Reduce motion if user prefers */
  @media (prefers-reduced-motion: reduce) {
    .animate-slide-up,
    .animate-fade-in {
      animation: none;
      opacity: 1;
    }
  }
}
</style> 