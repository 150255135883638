<template>
  <main class="px-6 md:px-20 py-8 md:py-16">
    <div class="max-w-7xl mx-auto">
      <div class="flex flex-col lg:flex-row justify-between gap-12">
        <!-- Left Section -->
        <div class="lg:max-w-xl">
          <h1 class="text-4xl md:text-6xl font-bold leading-tight mb-6 text-center lg:text-left text-black" v-html="t('home.hero.title').replace(/\n/g, '<br>')">
          </h1>
          <p class="text-gray-600 mb-8 text-center lg:text-left text-black">
            {{ t('home.hero.description') }}
          </p>
          <div class="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
            <Link href="/join" class="px-8 py-4 bg-[#034328] text-white rounded-full flex items-center justify-center hover:bg-[#024024] transition-colors" aria-label="Start booking your travel assistance">
              {{ t('home.hero.cta.booking') }} <span class="ml-2" aria-hidden="true">→</span>
            </Link>
            <Link href="/service-packages" class="px-8 py-4 border-2 border-[#034328] text-[#034328] rounded-full flex items-center justify-center hover:bg-[#034328] hover:text-white transition-colors" aria-label="Discover our service packages">
              {{ t('home.hero.cta.discover') }} <span class="ml-2" aria-hidden="true">→</span>
            </Link>
          </div>

          <!-- Features -->
          <div class="mt-20 grid grid-cols-1 sm:grid-cols-2 gap-10">
            <div class="text-center lg:text-left">
              <h2 class="text-xl font-semibold mb-2 text-black">{{ t('home.hero.features.support.title') }}</h2>
              <p class="text-gray-600">{{ t('home.hero.features.support.description') }}</p>
            </div>
            <div class="text-center lg:text-left">
              <h2 class="text-xl font-semibold mb-2 text-black">{{ t('home.hero.features.reliable.title') }}</h2>
              <p class="text-gray-600">{{ t('home.hero.features.reliable.description') }}</p>
            </div>
          </div>
        </div>

        <!-- Right Section - Images -->
        <div class="relative lg:flex-1">
          <!-- Rating Badge -->
          <div class="absolute top-4 sm:top-10 right-4 sm:right-10 bg-black text-white px-4 py-2 rounded-full z-10">
            Care in the air
          </div>
          
          <!-- Images with Play Button -->
          <div class="relative rounded-3xl overflow-hidden shadow-2xl">
            <ProgressiveImage
              :src="heroThumbnail"
              alt="Travel Assistance"
              :aspect-ratio="0.75"
              class="w-full h-full"
              :is-above-the-fold="true"
            />
            <div class="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent pointer-events-none"></div>
            
            <!-- Play Button - Now inside the image container -->
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="relative w-14 sm:w-16 md:w-20 h-14 sm:h-16 md:h-20">
                <button 
                  type="button"
                  @click.stop="openVideo"
                  aria-label="Play video"
                  class="absolute inset-0 bg-[#034328]/90 backdrop-blur-sm rounded-full flex items-center justify-center cursor-pointer hover:bg-[#024024] transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#034328] group"
                >
                  <Play class="text-white w-5 sm:w-6 md:w-7 h-5 sm:h-6 md:h-7 transform transition-transform group-hover:scale-110" aria-hidden="true" />
                </button>
                <div class="absolute inset-0 border-2 sm:border-4 border-[#034328]/60 rounded-full animate-ping pointer-events-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Video Modal -->
    <VideoModal
      v-model="showVideo"
      :video-url="videoUrl"
      title="YouTube video player"
    />
  </main>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useI18n } from 'vue-i18n'
import { Play } from 'lucide-vue-next'
import VideoModal from '@/Components/VideoModal.vue'
import ProgressiveImage from '@/Components/common/ProgressiveImage.vue'
import heroThumbnail from '~/videos/thumbnails/hero.jpg'

const { t } = useI18n()
const showVideo = ref(false)

const videoUrl = computed(() => {
  return showVideo.value 
    ? 'https://www.youtube.com/embed/WmE2ZyPVE1w?autoplay=1' 
    : ''
})

const openVideo = () => {
  showVideo.value = true
}

const closeVideo = () => {
  showVideo.value = false
}
</script>

<style scoped>
.animate-ping {
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%, 100% {
    transform: scale(1.75);
    opacity: 0;
  }
}

/* Optimize animations for mobile */
@media (max-width: 640px) {
  .animate-ping {
    animation-duration: 1.2s;
  }
  
  @keyframes ping {
    75%, 100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
}
</style> 