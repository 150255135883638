<!-- resources/js/Components/home/DfdSection.vue -->
<template>
    <section class="py-12 sm:py-16 bg-gray-50">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="text-center mb-8 sm:mb-12 scroll-reveal">
                <h2 class="text-2xl sm:text-3xl font-bold text-gray-900 lg:text-4xl mb-3 sm:mb-4">
                    {{ $t('home.dfd.title') }}
                </h2>
                <p class="text-base sm:text-lg text-gray-600 max-w-3xl mx-auto">
                    {{ $t('home.dfd.subtitle') }}
                </p>
            </div>

            <div class="space-y-4 sm:space-y-6">
                <!-- Main Image Container -->
                <div class="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 hover:shadow-2xl scroll-reveal">
                    <img 
                        :src="dfdImage" 
                        alt="Buddy4Travel Service Flow Diagram" 
                        class="w-full h-auto"
                    />
                </div>

                <!-- Content Section - Always Visible -->
                <div class="bg-white rounded-xl shadow-lg p-6 sm:p-8 scroll-reveal">
                    <!-- Feature Grid -->
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 stagger-children">
                        <!-- Booking Feature -->
                        <ServiceItem
                            :title="$t('home.dfd.features.booking.title')"
                            :description="$t('home.dfd.features.booking.description')"
                            icon="fa-users"
                            class="animate-fade-in animation-delay-500"
                        />

                        <!-- Matching Feature -->
                        <ServiceItem
                            :title="$t('home.dfd.features.matching.title')"
                            :description="$t('home.dfd.features.matching.description')"
                            icon="fa-check-circle"
                            class="animate-fade-in animation-delay-600"
                        />

                        <!-- Assistance Feature -->
                        <ServiceItem
                            :title="$t('home.dfd.features.assistance.title')"
                            :description="$t('home.dfd.features.assistance.description')"
                            icon="fa-star"
                            class="animate-fade-in animation-delay-700"
                        />
                    </div>
                    
                    <!-- Description -->
                    <div class="border-t border-gray-100 pt-6 mt-6 scroll-reveal">
                        <p class="text-gray-600 max-w-3xl text-center mx-auto">
                            {{ $t('home.dfd.description') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dfdImage from '~/media/dfd_horizontal.jpg'
import { Users, CheckCircle, Star } from 'lucide-vue-next'
import ServiceItem from '../../Pages/About/Components/ServiceItem.vue'

const { t, locale } = useI18n()

// Track if initial reveal has happened
const hasInitialReveal = ref(false)

// Add scroll reveal functionality
const handleIntersect = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      // Check if element is already in viewport on initial load
      const rect = entry.target.getBoundingClientRect()
      const isInViewport = (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )

      if (isInViewport || hasInitialReveal.value) {
        entry.target.classList.add('revealed', 'no-animation')
      } else {
        entry.target.classList.add('revealed')
      }
      observer.unobserve(entry.target)
    }
  })
}

const observer = new IntersectionObserver(handleIntersect, {
  threshold: 0.2,
  rootMargin: '0px'
})

// Watch for language changes to preserve reveal state
watch(locale, () => {
  if (hasInitialReveal.value) {
    // Re-reveal all elements immediately without animation
    document.querySelectorAll('.scroll-reveal, .stagger-children').forEach(el => {
      el.classList.add('revealed', 'no-animation')
    })
  }
})

onMounted(() => {
  // Small delay to ensure accurate viewport calculation
  setTimeout(() => {
    document.querySelectorAll('.scroll-reveal, .stagger-children').forEach(el => {
      observer.observe(el)
    })
    hasInitialReveal.value = true
  }, 100)
})

onUnmounted(() => {
  observer.disconnect()
})
</script>

<style scoped>
.animate-slide-up {
    animation: slide-up 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.animate-fade-in {
    animation: fade-in 0.5s ease-out forwards;
}

.animate-scale-up {
    animation: scale-up 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}

.animation-delay-200 {
    animation-delay: 200ms;
}

.animation-delay-400 {
    animation-delay: 400ms;
}

.animation-delay-500 {
    animation-delay: 500ms;
}

.animation-delay-600 {
    animation-delay: 600ms;
}

.animation-delay-700 {
    animation-delay: 700ms;
}

.animation-delay-800 {
    animation-delay: 800ms;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scale-up {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* No animation for initially visible elements */
:deep(.scroll-reveal.no-animation),
:deep(.stagger-children.no-animation > *) {
    transition: none !important;
    opacity: 1;
    transform: none;
}

:deep(.scroll-reveal) {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

:deep(.scroll-reveal.revealed) {
    opacity: 1;
    transform: translateY(0);
}

/* Stagger children animations */
:deep(.stagger-children > *) {
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

:deep(.stagger-children.revealed > *) {
    opacity: 1;
    transform: translateY(0);
}

/* Only apply delays to elements that weren't initially visible */
:deep(.stagger-children.revealed:not(.no-animation) > *:nth-child(1)) { transition-delay: 100ms; }
:deep(.stagger-children.revealed:not(.no-animation) > *:nth-child(2)) { transition-delay: 200ms; }
:deep(.stagger-children.revealed:not(.no-animation) > *:nth-child(3)) { transition-delay: 300ms; }

/* Respect user's motion preferences */
@media (prefers-reduced-motion: reduce) {
    :deep(.scroll-reveal),
    :deep(.stagger-children > *) {
        transition: none !important;
        opacity: 1;
        transform: none;
    }
}
</style> 