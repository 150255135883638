<template>
  <div class="px-6 md:px-20 py-8 md:py-16 bg-gray-50 border-t border-gray-200">
    <div class="max-w-7xl mx-auto">
      <div class="flex flex-wrap justify-center">
        <!-- Basic package stats -->
        <div class="w-1/2 md:w-1/4 relative stat-item">
          <div class="flex flex-col items-center px-4 py-4">
            <div class="flex items-center gap-2 mb-2">
              <span class="text-yellow-500 text-2xl">😊</span>
              <h2 class="text-3xl md:text-4xl font-bold text-black">{{ $t('home.stats.basic.count') }}</h2>
            </div>
            <p class="text-gray-600 text-center">{{ $t('home.stats.basic.title') }}</p>
          </div>
          <div class="hidden md:block absolute right-0 top-1/2 transform -translate-y-1/2 h-16 w-px bg-gray-200"></div>
        </div>
        
        <!-- Medium package stats -->
        <div class="w-1/2 md:w-1/4 relative stat-item">
          <div class="flex flex-col items-center px-4 py-4">
            <div class="flex items-center gap-2 mb-2">
              <span class="text-yellow-500 text-2xl">💰</span>
              <h2 class="text-3xl md:text-4xl font-bold text-black">{{ $t('home.stats.medium.count') }}</h2>
            </div>
            <p class="text-gray-600 text-center">{{ $t('home.stats.medium.title') }}</p>
          </div>
          <div class="hidden md:block absolute right-0 top-1/2 transform -translate-y-1/2 h-16 w-px bg-gray-200"></div>
        </div>
        
        <!-- Premium package stats -->
        <div class="w-1/2 md:w-1/4 relative stat-item">
          <div class="flex flex-col items-center px-4 py-4">
            <div class="flex items-center gap-2 mb-2">
              <span class="text-yellow-500 text-2xl">👑</span>
              <h2 class="text-3xl md:text-4xl font-bold text-black">{{ $t('home.stats.premium.count') }}</h2>
            </div>
            <p class="text-gray-600 text-center">{{ $t('home.stats.premium.title') }}</p>
          </div>
          <div class="hidden md:block absolute right-0 top-1/2 transform -translate-y-1/2 h-16 w-px bg-gray-200"></div>
        </div>
        
        <!-- Carers stats -->
        <div class="w-1/2 md:w-1/4 relative stat-item">
          <div class="flex flex-col items-center px-4 py-4">
            <div class="flex items-center gap-2 mb-2">
              <span class="text-yellow-500 text-2xl">🌟</span>
              <h2 class="text-3xl md:text-4xl font-bold text-black">{{ $t('home.stats.carers.count') }}</h2>
            </div>
            <p class="text-gray-600 text-center">{{ $t('home.stats.carers.title') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

// Setup i18n
const { t } = useI18n()
</script>

<style scoped>
.stat-item h2 {
  transition: transform 0.3s ease;
}

.stat-item:hover h2 {
  transform: translateY(-2px);
}
</style> 