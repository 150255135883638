<template>
    <Head title="Home">
        <meta
            name="description"
            :content="t('welcome.intro.part1')"
        />
    </Head>
    <MainLayout>
      <HeroSection class="scroll-animation" data-animation="fade-up" />
      <DfdSection class="scroll-animation bg-primary-light/[0.02]" data-animation="fade-up" />
      <StatsSection class="scroll-animation bg-secondary/[0.02]" data-animation="fade-up" />
      <HowItWorksSection class="scroll-animation bg-primary-light/[0.02]" data-animation="fade-up" />
      <TravelSupportServices class="scroll-animation bg-secondary/[0.02]" data-animation="fade-up" />
      <JourneySection class="scroll-animation bg-primary-light/[0.02]" data-animation="fade-up" />
    </MainLayout>
  </template>
  
  <script setup lang="ts">
  import { Head } from '@inertiajs/vue3'
  import MainLayout from '@/Layouts/MainLayout.vue'
  import HeroSection from '@/Components/home/HeroSection.vue'
  import DfdSection from '@/Components/home/DfdSection.vue'
  import StatsSection from '@/Components/home/StatsSection.vue'
  import HowItWorksSection from '@/Components/home/HowItWorksSection.vue'
  import TravelSupportServices from '@/Components/home/TravelSupportServices.vue'
  import JourneySection from '@/Components/home/JourneySection.vue'
  import { useScrollAnimation } from '@/Composables/useScrollAnimation'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()
  useScrollAnimation()
  </script>

  <style>
  .scroll-animation {
    opacity: 0;
    transform: translateY(15px);
    transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .scroll-animation.animate-in {
    opacity: 1;
    transform: translateY(0);
  }

  /* No animation for initially visible elements */
  .scroll-animation.no-animation {
    transition: none !important;
  }

  /* Add different animation delays for each section that scrolls into view */
  .scroll-animation:not(.no-animation):nth-child(3) { transition-delay: 100ms; }
  .scroll-animation:not(.no-animation):nth-child(4) { transition-delay: 150ms; }
  .scroll-animation:not(.no-animation):nth-child(5) { transition-delay: 200ms; }
  .scroll-animation:not(.no-animation):nth-child(6) { transition-delay: 250ms; }

  /* Reduce motion if user prefers */
  @media (prefers-reduced-motion: reduce) {
    .scroll-animation {
      transition: none !important;
      opacity: 1;
      transform: none;
    }
  }
  </style>