<template>
  <div class="w-full py-8">
    <div class="container mx-auto px-4">
      <!-- Header Section -->
      <div class="mb-8 text-center">
        <div class="flex items-center justify-center gap-2 mb-3">
          <MapPin class="w-6 h-6 text-[#2B8B6F] transition-colors duration-300 group-hover:text-[#1A5242]" />
          <span class="text-[#2B8B6F] font-medium">{{ $t('home.journey.subtitle') }}</span>
        </div>
        <h2 class="text-3xl md:text-4xl font-bold mb-4 text-black">{{ $t('home.journey.title') }}</h2>
        <p class="text-gray-600 max-w-2xl mx-auto">
          {{ $t('home.journey.description') }}
        </p>
      </div>
      
      <div class="grid lg:grid-cols-2 gap-6">
        <!-- International Routes Section -->
        <div class="bg-white rounded-3xl overflow-hidden shadow-lg">
          <div class="p-6">
            <div class="flex items-center gap-3 mb-6">
              <Globe class="w-5 h-5 text-[#2B8B6F] transition-colors duration-300 group-hover:text-[#1A5242]" />
              <h3 class="text-xl font-semibold text-black">{{ $t('home.journey.routes.international.title') }}</h3>
            </div>
            <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-3">
              <div v-for="route in internationalRoutes" 
                  :key="`int-${route.origin}-${route.destination}`"
                  class="relative">
                <button @click="(e) => toggleRoute(route, e)"
                    class="w-full bg-gray-50 rounded-xl px-3 py-3 group hover:bg-gray-100 transition-all duration-300 cursor-pointer">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center space-x-3 min-w-0 flex-1">
                      <span class="text-lg flex-shrink-0">{{ countryFlags[route.country] }}</span>
                      <Plane class="w-4 h-4 text-[#2B8B6F] flex-shrink-0 transition-colors duration-300 group-hover:text-[#1A5242]" />
                      <span class="text-gray-900 text-sm truncate">{{ route.origin }}</span>
                      <ArrowRight class="w-4 h-4 text-[#2B8B6F] opacity-50 flex-shrink-0 transform arrow-right transition-colors duration-300 group-hover:text-[#1A5242]" />
                      <span class="text-gray-900 text-sm truncate">{{ route.destination }}</span>
                    </div>
                    <ChevronDown class="w-4 h-4 text-[#2B8B6F] transition-transform flex-shrink-0 ml-2 transition-colors duration-300 group-hover:text-[#1A5242]"
                              :class="{ 'rotate-180': route.isExpanded }" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Domestic Routes Section -->
        <div class="bg-white rounded-3xl overflow-hidden shadow-lg">
          <div class="p-6">
            <div class="flex items-center gap-3 mb-6">
              <Map class="w-5 h-5 text-[#2B8B6F] transition-colors duration-300 group-hover:text-[#1A5242]" />
              <h3 class="text-xl font-semibold text-black">{{ $t('home.journey.routes.domestic.title') }}</h3>
            </div>
            <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-3">
              <div v-for="route in domesticRoutes" 
                  :key="`dom-${route.origin}-${route.destination}`"
                  class="relative">
                <button @click="(e) => toggleRoute(route, e)"
                    class="w-full bg-gray-50 rounded-xl px-3 py-3 group hover:bg-gray-100 transition-all duration-300 cursor-pointer">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center space-x-3 min-w-0 flex-1">
                      <span class="text-lg flex-shrink-0">{{ countryFlags[route.country] }}</span>
                      <Plane class="w-4 h-4 text-[#2B8B6F] flex-shrink-0 transition-colors duration-300 group-hover:text-[#1A5242]" />
                      <span class="text-gray-900 text-sm truncate">{{ route.origin }}</span>
                      <ArrowRight class="w-4 h-4 text-[#2B8B6F] opacity-50 flex-shrink-0 transform arrow-right transition-colors duration-300 group-hover:text-[#1A5242]" />
                      <span class="text-gray-900 text-sm truncate">{{ route.destination }}</span>
                    </div>
                    <ChevronDown class="w-4 h-4 text-[#2B8B6F] transition-transform flex-shrink-0 ml-2 transition-colors duration-300 group-hover:text-[#1A5242]"
                              :class="{ 'rotate-180': route.isExpanded }" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Route Details Modal (using Headless UI instead of v-if) -->
    <TransitionRoot appear :show="!!activeRoute" as="template">
      <Dialog as="div" @close="closeRoute" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30 backdrop-blur-sm" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel 
                class="w-full max-w-md transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all scale-in-center"
              >
                <div class="flex justify-between items-center mb-6">
                  <DialogTitle as="h3" class="text-xl font-semibold flex items-center gap-2 text-black">
                    <span v-if="activeRoute">{{ countryFlags[activeRoute.country] }}</span>
                    {{ $t('home.journey.modal.title') }}
                  </DialogTitle>
                  <button @click="closeRoute" class="text-gray-400 hover:text-gray-600 transition-colors">
                    <X class="w-5 h-5" />
                  </button>
                </div>

                <div v-if="activeRoute" class="space-y-6">
                  <div class="flex items-center justify-center space-x-6">
                    <span class="text-gray-900 text-lg">{{ activeRoute.origin }}</span>
                    <Plane class="w-5 h-5 text-[#2B8B6F] transform -rotate-45" />
                    <span class="text-gray-900 text-lg">{{ activeRoute.destination }}</span>
                  </div>

                  <div class="grid grid-cols-2 gap-4">
                    <div class="text-center p-4 bg-gray-50 rounded-xl">
                      <div class="text-2xl font-bold text-[#2B8B6F] mb-1">{{ activeRoute.carers }}</div>
                      <div class="text-gray-600 text-sm">{{ $t('home.journey.modal.stats.carers') }}</div>
                    </div>
                    <div class="text-center p-4 bg-gray-50 rounded-xl">
                      <div class="text-2xl font-bold text-[#2B8B6F] mb-1">{{ activeRoute.careseekers }}</div>
                      <div class="text-gray-600 text-sm">{{ $t('home.journey.modal.stats.careseekers') }}</div>
                    </div>
                  </div>

                  <p class="text-sm text-gray-500 border-t border-gray-100 pt-4">
                    {{ $t('home.journey.modal.stats.availableUsers') }}
                  </p>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { MapPin, Globe, Map, Plane, ArrowRight, ChevronDown, X } from 'lucide-vue-next'
import { useLocation } from '@/Composables/useLocation'
import { globalRoutes, routesByCountry } from '@/Data/routes'

interface Route {
  origin: string
  destination: string
  carers: number
  careseekers: number
  isExpanded: boolean
  country: keyof typeof countryFlags
}

// Country flags mapping
const countryFlags = {
  au: '🇦🇺', us: '🇺🇸', uk: '🇬🇧', sg: '🇸🇬', jp: '🇯🇵',
  ae: '🇦🇪', nz: '🇳🇿', ca: '🇨🇦', de: '🇩🇪', fr: '🇫🇷',
  in: '🇮🇳', kr: '🇰🇷', my: '🇲🇾', id: '🇮🇩', th: '🇹🇭'
} as const

// Use location composable
const { countryCode, loading: locationLoading } = useLocation()

// Reactive references
const activeRoute = ref<Route | null>(null)
const domesticRoutes = ref<Route[]>([])
const internationalRoutes = ref<Route[]>([])

// Computed property for section title
const sectionTitle = computed(() => {
  if (!countryCode.value) return 'Discover Popular Travel Routes'
  const code = countryCode.value.toLowerCase() as keyof typeof countryFlags
  return `Popular Routes in ${countryFlags[code] || ''}`
})

// Watch for country code changes
watch(countryCode, (newCountryCode: string | null) => {
  if (!newCountryCode) {
    domesticRoutes.value = globalRoutes.domestic as Route[]
    internationalRoutes.value = globalRoutes.international as Route[]
    return
  }
  
  const countryKey = newCountryCode.toLowerCase()
  const countryRoutes = routesByCountry[countryKey] || globalRoutes
  domesticRoutes.value = countryRoutes.domestic as Route[]
  internationalRoutes.value = countryRoutes.international as Route[]
}, { immediate: true })

const toggleRoute = (route: Route, event: Event) => {
  event.preventDefault()
  activeRoute.value = route
}

const closeRoute = () => {
  activeRoute.value = null
}
</script>

<style scoped>
.group:hover .arrow-right {
  transform: translateX(4px);
  transition: transform 0.3s ease;
}

.scale-in-center {
  animation: scaleInCenter 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scaleInCenter {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.backdrop-blur-sm {
  backdrop-filter: blur(8px);
}

button:focus {
  outline: 2px solid #2B8B6F;
  outline-offset: 2px;
}

/* Update hover effect for route cards */
.group {
  transition: all 0.2s ease;
}

.group:hover {
  transform: translateY(-1px);
  box-shadow: 0 0 12px rgba(43, 139, 111, 0.1);
}

/* Subtle pulse animation for the arrow */
@keyframes subtlePulse {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 0.8; }
}

.arrow-right {
  animation: subtlePulse 2s ease-in-out infinite;
}
</style>