<template>
  <div class="px-4 sm:px-6 md:px-20 py-8 md:py-16 bg-gray-50">
    <div class="max-w-7xl mx-auto">
      <div class="text-center mb-8 md:mb-12">
        <div class="flex items-center justify-center gap-2 mb-2 md:mb-3">
          <ShieldCheck class="w-5 h-5 md:w-6 md:h-6 text-[#2B8B6F]" />
          <span class="text-[#2B8B6F] text-sm md:text-base font-medium">{{ t('home.security.subtitle') }}</span>
        </div>
        <h2 class="text-2xl sm:text-3xl md:text-4xl font-bold mb-3 md:mb-4 text-black">{{ t('home.security.title') }}</h2>
        <p class="text-gray-600 text-sm md:text-base max-w-2xl mx-auto">
          {{ t('home.security.description') }}
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
        <!-- Security Features Grid -->
        <div class="md:col-span-12 mb-4 md:mb-6">
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 md:gap-8">
            <div class="text-center h-full">
              <div class="bg-[#E8F5F1] backdrop-blur-sm rounded-2xl md:rounded-3xl p-8 md:p-10 group hover:bg-[#D1EBE4] transition-all duration-300 h-full flex flex-col items-center justify-center">
                <div class="mb-6">
                  <Fingerprint class="w-8 h-8 md:w-10 md:h-10 text-[#2B8B6F] transition-colors duration-300 group-hover:text-[#1A5242]" />
                </div>
                <h3 class="text-xl md:text-2xl font-semibold text-gray-900 mb-4">{{ t('home.security.features.identity.title') }}</h3>
                <p class="text-gray-600 text-base md:text-lg">{{ t('home.security.features.identity.description') }}</p>
              </div>
            </div>
            <div class="text-center h-full">
              <div class="bg-[#E8F5F1] backdrop-blur-sm rounded-2xl md:rounded-3xl p-8 md:p-10 group hover:bg-[#D1EBE4] transition-all duration-300 h-full flex flex-col items-center justify-center">
                <div class="mb-6">
                  <Lock class="w-8 h-8 md:w-10 md:h-10 text-[#2B8B6F] transition-colors duration-300 group-hover:text-[#1A5242]" />
                </div>
                <h3 class="text-xl md:text-2xl font-semibold text-gray-900 mb-4">{{ t('home.security.features.payments.title') }}</h3>
                <p class="text-gray-600 text-base md:text-lg">{{ t('home.security.features.payments.description') }}</p>
              </div>
            </div>
            <div class="text-center h-full">
              <div class="bg-[#E8F5F1] backdrop-blur-sm rounded-2xl md:rounded-3xl p-8 md:p-10 group hover:bg-[#D1EBE4] transition-all duration-300 h-full flex flex-col items-center justify-center">
                <div class="mb-6">
                  <Shield class="w-8 h-8 md:w-10 md:h-10 text-[#2B8B6F] transition-colors duration-300 group-hover:text-[#1A5242]" />
                </div>
                <h3 class="text-xl md:text-2xl font-semibold text-gray-900 mb-4">{{ t('home.security.features.privacy.title') }}</h3>
                <p class="text-gray-600 text-base md:text-lg">{{ t('home.security.features.privacy.description') }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Security Video -->
        <div class="md:col-span-12">
          <div class="relative rounded-2xl md:rounded-3xl overflow-hidden group h-[250px] sm:h-[275px] md:h-[300px]">
            <ProgressiveImage 
              :src="securityThumbnail" 
              alt="Security Measures"
              class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
              :is-above-the-fold="true"
            />
            <button 
              @click="openVideoModal('security')"
              class="absolute inset-0 w-full h-full flex items-center justify-center bg-black/30 hover:bg-black/40 transition-colors group cursor-pointer"
              aria-label="Watch video about our security measures">
              <div class="w-12 h-12 md:w-16 md:h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center group">
                <Play class="w-6 h-6 md:w-8 md:h-8 text-white fill-white transition-colors duration-300 group-hover:text-white/80" aria-hidden="true" />
              </div>
            </button>
            <div class="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent pointer-events-none">
              <div class="absolute bottom-0 left-0 right-0 p-4 sm:p-6 md:p-8">
                <h3 class="text-xl md:text-2xl font-bold text-white mb-2">{{ t('home.security.videos.security.title') }}</h3>
              </div>
            </div>
          </div>
        </div>

        <!-- Two Sections Below -->
        <div class="md:col-span-6">
          <div class="relative rounded-2xl md:rounded-3xl overflow-hidden group h-[250px] sm:h-[275px] md:h-[300px]">
            <ProgressiveImage 
              :src="carerThumbnail" 
              alt="Carer Experience"
              class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
            />
            <button
              @click="openVideoModal('carer')"
              class="absolute inset-0 w-full h-full flex items-center justify-center bg-black/30 hover:bg-black/40 transition-colors group cursor-pointer"
              aria-label="Watch video about carer experience">
              <div class="w-12 h-12 md:w-16 md:h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center group">
                <Play class="w-6 h-6 md:w-8 md:h-8 text-white fill-white transition-colors duration-300 group-hover:text-white/80" aria-hidden="true" />
              </div>
            </button>
            <div class="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent pointer-events-none">
              <div class="absolute bottom-0 left-0 right-0 p-4 sm:p-5 md:p-6">
                <h3 class="text-xl md:text-2xl font-bold text-white mb-1 md:mb-2">{{ t('home.security.videos.carer.title') }}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="md:col-span-6">
          <div class="relative rounded-2xl md:rounded-3xl overflow-hidden group h-[250px] sm:h-[275px] md:h-[300px]">
            <ProgressiveImage 
              :src="seekerThumbnail" 
              alt="Care Seeker Experience"
              class="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
            />
            <button
              @click="openVideoModal('seeker')"
              class="absolute inset-0 w-full h-full flex items-center justify-center bg-black/30 hover:bg-black/40 transition-colors group cursor-pointer"
              aria-label="Watch video about care seeker experience">
              <div class="w-12 h-12 md:w-16 md:h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center group">
                <Play class="w-6 h-6 md:w-8 md:h-8 text-white fill-white transition-colors duration-300 group-hover:text-white/80" aria-hidden="true" />
              </div>
            </button>
            <div class="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent pointer-events-none">
              <div class="absolute bottom-0 left-0 right-0 p-4 sm:p-5 md:p-6">
                <h3 class="text-xl md:text-2xl font-bold text-white mb-1 md:mb-2">{{ t('home.security.videos.seeker.title') }}</h3>
                <p class="text-white/90 text-sm md:text-base">{{ t('home.security.videos.seeker.description') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Video Modal -->
    <VideoModal
      v-model="isVideoActive"
      :video-url="activeVideoSrc"
      :title="getVideoTitle(activeVideo)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ShieldCheck from 'lucide-vue-next/dist/esm/icons/shield-check'
import Fingerprint from 'lucide-vue-next/dist/esm/icons/fingerprint'
import Lock from 'lucide-vue-next/dist/esm/icons/lock'
import Shield from 'lucide-vue-next/dist/esm/icons/shield'
import Play from 'lucide-vue-next/dist/esm/icons/play'
import VideoModal from '@/Components/VideoModal.vue'
import ProgressiveImage from '@/Components/common/ProgressiveImage.vue'
import carerThumbnail from '~/videos/thumbnails/carer.jpg'
import securityThumbnail from '~/videos/thumbnails/security_measures.jpg'
import seekerThumbnail from '~/videos/thumbnails/care-seeker.jpg'

const { t } = useI18n()

const activeVideo = ref<'security' | 'carer' | 'seeker' | null>(null)

const isVideoActive = computed({
  get: () => activeVideo.value !== null,
  set: (value: boolean) => {
    if (!value) activeVideo.value = null
  }
})

const videoSources = {
  security: 'https://www.youtube.com/embed/eOzR_vRuoBw',
  carer: 'https://www.youtube.com/embed/wkhAVLw6mOQ',
  seeker: 'https://www.youtube.com/embed/omFuLa7H2_k'
}

const activeVideoSrc = computed(() => {
  if (!activeVideo.value) return ''
  return `${videoSources[activeVideo.value]}?autoplay=1`
})

const openVideoModal = (type: 'security' | 'carer' | 'seeker') => {
  activeVideo.value = type
}

const closeVideoModal = () => {
  activeVideo.value = null
}

// Get video title from translations
const getVideoTitle = (videoType: 'security' | 'carer' | 'seeker' | null) => {
  if (!videoType) return ''
  return videoType === 'security' 
    ? t('home.security.videos.security.title')
    : videoType === 'carer'
    ? t('home.security.videos.carer.title')
    : t('home.security.videos.seeker.title')
}
</script>

<style scoped>
.group:hover .group-hover\:scale-105 {
  transform: scale(1.05);
}

.backdrop-blur-sm {
  backdrop-filter: blur(4px);
}
</style> 