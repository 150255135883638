interface Route {
  origin: string
  destination: string
  carers: number
  careseekers: number
  isExpanded: boolean
  country: string
}

interface RouteCollection {
  domestic: Route[]
  international: Route[]
}

interface CountryRoutes {
  [key: string]: RouteCollection
}

export const globalRoutes: RouteCollection = {
  domestic: [
    {
      origin: 'Sydney',
      destination: 'Melbourne',
      carers: 42,
      careseekers: 65,
      isExpanded: false,
      country: 'au'
    },
    {
      origin: 'New York',
      destination: 'Los Angeles',
      carers: 65,
      careseekers: 92,
      isExpanded: false,
      country: 'us'
    },
    {
      origin: 'London',
      destination: 'Manchester',
      carers: 52,
      careseekers: 78,
      isExpanded: false,
      country: 'uk'
    },
    {
      origin: 'Toronto',
      destination: 'Vancouver',
      carers: 45,
      careseekers: 67,
      isExpanded: false,
      country: 'ca'
    },
    {
      origin: 'Berlin',
      destination: 'Munich',
      carers: 38,
      careseekers: 59,
      isExpanded: false,
      country: 'de'
    },
    {
      origin: 'Paris',
      destination: 'Nice',
      carers: 41,
      careseekers: 63,
      isExpanded: false,
      country: 'fr'
    },
    {
      origin: 'Mumbai',
      destination: 'Delhi',
      carers: 58,
      careseekers: 87,
      isExpanded: false,
      country: 'in'
    },
    {
      origin: 'Tokyo',
      destination: 'Osaka',
      carers: 51,
      careseekers: 76,
      isExpanded: false,
      country: 'jp'
    }
  ],
  international: [
    {
      origin: 'London',
      destination: 'New York',
      carers: 72,
      careseekers: 105,
      isExpanded: false,
      country: 'uk'
    },
    {
      origin: 'Sydney',
      destination: 'Singapore',
      carers: 58,
      careseekers: 84,
      isExpanded: false,
      country: 'au'
    },
    {
      origin: 'Los Angeles',
      destination: 'Tokyo',
      carers: 63,
      careseekers: 89,
      isExpanded: false,
      country: 'us'
    },
    {
      origin: 'Dubai',
      destination: 'London',
      carers: 57,
      careseekers: 83,
      isExpanded: false,
      country: 'ae'
    },
    {
      origin: 'Singapore',
      destination: 'Tokyo',
      carers: 49,
      careseekers: 72,
      isExpanded: false,
      country: 'sg'
    },
    {
      origin: 'Paris',
      destination: 'New York',
      carers: 61,
      careseekers: 88,
      isExpanded: false,
      country: 'fr'
    },
    {
      origin: 'Mumbai',
      destination: 'Dubai',
      carers: 64,
      careseekers: 93,
      isExpanded: false,
      country: 'in'
    },
    {
      origin: 'Seoul',
      destination: 'Tokyo',
      carers: 53,
      careseekers: 79,
      isExpanded: false,
      country: 'kr'
    }
  ]
}

export const routesByCountry: CountryRoutes = {
  au: {
    domestic: [
      { 
        origin: 'Sydney', 
        destination: 'Melbourne', 
        carers: 42,
        careseekers: 65,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Brisbane', 
        destination: 'Perth', 
        carers: 38,
        careseekers: 51,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Melbourne', 
        destination: 'Adelaide', 
        carers: 31,
        careseekers: 47,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Gold Coast', 
        destination: 'Sydney', 
        carers: 35,
        careseekers: 54,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Perth', 
        destination: 'Darwin', 
        carers: 28,
        careseekers: 43,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Canberra', 
        destination: 'Brisbane', 
        carers: 25,
        careseekers: 39,
        isExpanded: false,
        country: 'au'
      }
    ],
    international: [
      { 
        origin: 'Sydney', 
        destination: 'Singapore', 
        carers: 58,
        careseekers: 84,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Melbourne', 
        destination: 'Tokyo', 
        carers: 45,
        careseekers: 67,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Brisbane', 
        destination: 'Dubai', 
        carers: 41,
        careseekers: 63,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Perth', 
        destination: 'London', 
        carers: 37,
        careseekers: 52,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Sydney', 
        destination: 'Los Angeles', 
        carers: 43,
        careseekers: 61,
        isExpanded: false,
        country: 'au'
      },
      { 
        origin: 'Melbourne', 
        destination: 'Delhi', 
        carers: 39,
        careseekers: 58,
        isExpanded: false,
        country: 'au'
      }
    ]
  },
  us: {
    domestic: [
      {
        origin: 'New York',
        destination: 'Los Angeles',
        carers: 65,
        careseekers: 92,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'San Francisco',
        destination: 'Chicago',
        carers: 48,
        careseekers: 73,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Miami',
        destination: 'Seattle',
        carers: 39,
        careseekers: 58,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Boston',
        destination: 'Houston',
        carers: 42,
        careseekers: 64,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Las Vegas',
        destination: 'Orlando',
        carers: 35,
        careseekers: 51,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Denver',
        destination: 'Phoenix',
        carers: 31,
        careseekers: 47,
        isExpanded: false,
        country: 'us'
      }
    ],
    international: [
      {
        origin: 'New York',
        destination: 'London',
        carers: 72,
        careseekers: 105,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Los Angeles',
        destination: 'Tokyo',
        carers: 63,
        careseekers: 89,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'San Francisco',
        destination: 'Sydney',
        carers: 54,
        careseekers: 78,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Miami',
        destination: 'Paris',
        carers: 48,
        careseekers: 71,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Chicago',
        destination: 'Dubai',
        carers: 45,
        careseekers: 67,
        isExpanded: false,
        country: 'us'
      },
      {
        origin: 'Boston',
        destination: 'Singapore',
        carers: 41,
        careseekers: 62,
        isExpanded: false,
        country: 'us'
      }
    ]
  },
  uk: {
    domestic: [
      {
        origin: 'London',
        destination: 'Manchester',
        carers: 52,
        careseekers: 78,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Edinburgh',
        destination: 'Birmingham',
        carers: 43,
        careseekers: 65,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Glasgow',
        destination: 'Bristol',
        carers: 38,
        careseekers: 57,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Liverpool',
        destination: 'Leeds',
        carers: 35,
        careseekers: 52,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Newcastle',
        destination: 'Cardiff',
        carers: 31,
        careseekers: 47,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Belfast',
        destination: 'Southampton',
        carers: 28,
        careseekers: 42,
        isExpanded: false,
        country: 'uk'
      }
    ],
    international: [
      {
        origin: 'London',
        destination: 'New York',
        carers: 68,
        careseekers: 97,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Manchester',
        destination: 'Dubai',
        carers: 57,
        careseekers: 83,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Edinburgh',
        destination: 'Singapore',
        carers: 49,
        careseekers: 72,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Birmingham',
        destination: 'Sydney',
        carers: 45,
        careseekers: 67,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'London',
        destination: 'Tokyo',
        carers: 52,
        careseekers: 76,
        isExpanded: false,
        country: 'uk'
      },
      {
        origin: 'Glasgow',
        destination: 'Los Angeles',
        carers: 43,
        careseekers: 64,
        isExpanded: false,
        country: 'uk'
      }
    ]
  },
  in: {
    domestic: [
      {
        origin: 'Mumbai',
        destination: 'Delhi',
        carers: 58,
        careseekers: 87,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Bangalore',
        destination: 'Chennai',
        carers: 45,
        careseekers: 68,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Hyderabad',
        destination: 'Kolkata',
        carers: 41,
        careseekers: 62,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Delhi',
        destination: 'Pune',
        carers: 38,
        careseekers: 57,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Chennai',
        destination: 'Mumbai',
        carers: 35,
        careseekers: 53,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Kolkata',
        destination: 'Bangalore',
        carers: 32,
        careseekers: 48,
        isExpanded: false,
        country: 'in'
      }
    ],
    international: [
      {
        origin: 'Mumbai',
        destination: 'Dubai',
        carers: 64,
        careseekers: 93,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Delhi',
        destination: 'Singapore',
        carers: 57,
        careseekers: 85,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Bangalore',
        destination: 'London',
        carers: 51,
        careseekers: 76,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Chennai',
        destination: 'Sydney',
        carers: 46,
        careseekers: 69,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Mumbai',
        destination: 'New York',
        carers: 53,
        careseekers: 79,
        isExpanded: false,
        country: 'in'
      },
      {
        origin: 'Delhi',
        destination: 'Tokyo',
        carers: 48,
        careseekers: 72,
        isExpanded: false,
        country: 'in'
      }
    ]
  },
  jp: {
    domestic: [
      {
        origin: 'Tokyo',
        destination: 'Osaka',
        carers: 51,
        careseekers: 76,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Osaka',
        destination: 'Fukuoka',
        carers: 43,
        careseekers: 64,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Nagoya',
        destination: 'Sapporo',
        carers: 38,
        careseekers: 57,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Fukuoka',
        destination: 'Tokyo',
        carers: 45,
        careseekers: 67,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Sapporo',
        destination: 'Osaka',
        carers: 36,
        careseekers: 54,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Tokyo',
        destination: 'Nagoya',
        carers: 41,
        careseekers: 61,
        isExpanded: false,
        country: 'jp'
      }
    ],
    international: [
      {
        origin: 'Tokyo',
        destination: 'Seoul',
        carers: 59,
        careseekers: 88,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Osaka',
        destination: 'Singapore',
        carers: 52,
        careseekers: 78,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Tokyo',
        destination: 'Los Angeles',
        carers: 63,
        careseekers: 94,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Nagoya',
        destination: 'Sydney',
        carers: 47,
        careseekers: 70,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Tokyo',
        destination: 'London',
        carers: 56,
        careseekers: 84,
        isExpanded: false,
        country: 'jp'
      },
      {
        origin: 'Osaka',
        destination: 'Bangkok',
        carers: 44,
        careseekers: 66,
        isExpanded: false,
        country: 'jp'
      }
    ]
  },
  sg: {
    domestic: [
      {
        origin: 'Singapore',
        destination: 'Changi',
        carers: 35,
        careseekers: 52,
        isExpanded: false,
        country: 'sg'
      }
    ],
    international: [
      {
        origin: 'Singapore',
        destination: 'Tokyo',
        carers: 57,
        careseekers: 85,
        isExpanded: false,
        country: 'sg'
      },
      {
        origin: 'Singapore',
        destination: 'Sydney',
        carers: 54,
        careseekers: 81,
        isExpanded: false,
        country: 'sg'
      },
      {
        origin: 'Singapore',
        destination: 'London',
        carers: 61,
        careseekers: 91,
        isExpanded: false,
        country: 'sg'
      },
      {
        origin: 'Singapore',
        destination: 'Dubai',
        carers: 49,
        careseekers: 73,
        isExpanded: false,
        country: 'sg'
      },
      {
        origin: 'Singapore',
        destination: 'Mumbai',
        carers: 46,
        careseekers: 69,
        isExpanded: false,
        country: 'sg'
      },
      {
        origin: 'Singapore',
        destination: 'Seoul',
        carers: 43,
        careseekers: 64,
        isExpanded: false,
        country: 'sg'
      }
    ]
  },
  nz: {
    domestic: [
      {
        origin: 'Auckland',
        destination: 'Wellington',
        carers: 38,
        careseekers: 57,
        isExpanded: false,
        country: 'nz'
      },
      {
        origin: 'Wellington',
        destination: 'Christchurch',
        carers: 32,
        careseekers: 48,
        isExpanded: false,
        country: 'nz'
      },
      {
        origin: 'Christchurch',
        destination: 'Auckland',
        carers: 35,
        careseekers: 52,
        isExpanded: false,
        country: 'nz'
      },
      {
        origin: 'Hamilton',
        destination: 'Dunedin',
        carers: 28,
        careseekers: 42,
        isExpanded: false,
        country: 'nz'
      }
    ],
    international: [
      {
        origin: 'Auckland',
        destination: 'Sydney',
        carers: 51,
        careseekers: 76,
        isExpanded: false,
        country: 'nz'
      },
      {
        origin: 'Wellington',
        destination: 'Melbourne',
        carers: 47,
        careseekers: 70,
        isExpanded: false,
        country: 'nz'
      },
      {
        origin: 'Auckland',
        destination: 'Singapore',
        carers: 44,
        careseekers: 66,
        isExpanded: false,
        country: 'nz'
      },
      {
        origin: 'Christchurch',
        destination: 'Dubai',
        carers: 41,
        careseekers: 61,
        isExpanded: false,
        country: 'nz'
      }
    ]
  },
  kr: {
    domestic: [
      {
        origin: 'Seoul',
        destination: 'Busan',
        carers: 45,
        careseekers: 67,
        isExpanded: false,
        country: 'kr'
      },
      {
        origin: 'Incheon',
        destination: 'Jeju',
        carers: 38,
        careseekers: 57,
        isExpanded: false,
        country: 'kr'
      },
      {
        origin: 'Daegu',
        destination: 'Seoul',
        carers: 35,
        careseekers: 52,
        isExpanded: false,
        country: 'kr'
      },
      {
        origin: 'Busan',
        destination: 'Incheon',
        carers: 32,
        careseekers: 48,
        isExpanded: false,
        country: 'kr'
      }
    ],
    international: [
      {
        origin: 'Seoul',
        destination: 'Tokyo',
        carers: 53,
        careseekers: 79,
        isExpanded: false,
        country: 'kr'
      },
      {
        origin: 'Busan',
        destination: 'Singapore',
        carers: 48,
        careseekers: 72,
        isExpanded: false,
        country: 'kr'
      },
      {
        origin: 'Seoul',
        destination: 'Los Angeles',
        carers: 57,
        careseekers: 85,
        isExpanded: false,
        country: 'kr'
      },
      {
        origin: 'Incheon',
        destination: 'Sydney',
        carers: 45,
        careseekers: 67,
        isExpanded: false,
        country: 'kr'
      }
    ]
  }
} 