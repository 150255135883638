<template>
  <div class="progressive-img-container" :style="containerStyle">
    <!-- Tiny blurred image -->
    <img
      :src="src"
      :alt="alt"
      class="progressive-img blur"
      :class="{ 'blur-hidden': isLoaded }"
      :style="{ filter: 'blur(20px)', transform: 'scale(1.1)', objectFit }"
      :loading="isAboveTheFold ? 'eager' : 'lazy'"
    />
    <!-- Full resolution image -->
    <img
      :src="src"
      :alt="alt"
      class="progressive-img"
      :class="{ 'img-loaded': isLoaded }"
      :style="{ objectFit }"
      @load="onImageLoaded"
      :loading="isAboveTheFold ? 'eager' : 'lazy'"
      :fetchpriority="isAboveTheFold ? 'high' : 'auto'"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    default: ''
  },
  aspectRatio: {
    type: Number,
    default: null
  },
  objectFit: {
    type: String,
    default: 'cover'
  },
  isAboveTheFold: {
    type: Boolean,
    default: false
  }
});

const isLoaded = ref(false);

const containerStyle = computed(() => ({
  position: 'relative',
  width: '100%',
  ...(props.aspectRatio ? {
    paddingBottom: `${props.aspectRatio * 100}%`,
  } : {
    height: '100%'
  }),
  backgroundColor: '#f0f0f0', // Light gray placeholder
  overflow: 'hidden'
}));

const onImageLoaded = () => {
  isLoaded.value = true;
};
</script>

<style scoped>
.progressive-img-container {
  position: relative;
  overflow: hidden;
}

.progressive-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blur {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  will-change: opacity;
  transform: scale(1.1);
}

.blur-hidden {
  opacity: 0;
}

.img-loaded {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style> 