import { onMounted, onUnmounted } from 'vue'

export function useScrollAnimation(options = {}) {
  const defaultOptions = {
    threshold: 0.1,
    rootMargin: '50px',
    ...options
  }

  const handleIntersect = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate-in')
        observer.unobserve(entry.target)
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersect, defaultOptions)

  onMounted(() => {
    setTimeout(() => {
      const animatedElements = document.querySelectorAll('.scroll-animation')
      
      animatedElements.forEach((el, index) => {
        if (index < 2) {
          el.classList.add('animate-in', 'no-animation')
          return
        }

        const rect = el.getBoundingClientRect()
        const isInViewport = (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )

        if (isInViewport) {
          el.classList.add('animate-in', 'no-animation')
        } else {
          observer.observe(el)
        }
      })
    }, 50)
  })

  onUnmounted(() => {
    observer.disconnect()
  })
} 